<template>
  <div>
    <div class="partner-info main">
      <div class="title">파트너 정보 관리</div>
      <div class="content">
        <div
          v-for="(form, index) in formData.filter((item) => item.field !== 'operating_end')"
          :key="`form-${index}`"
          class="form-item">
          <div class="form-label">
            {{ form.label }}
            <span v-if="form.validate.required" class="required"> *</span>
          </div>
          <div class="form-content">
            <template v-if="form.component_name === 'sns'">
              <div class="sns flex-align" style="gap: 12px">
                <img v-if="user.kakao_id" src="/static/icon/ic_kakao_circle.svg" />
                <img v-if="user.naver_id" src="/static/icon/ic_naver_circle.svg" />
                <img v-if="user.facebook_id" src="/static/icon/ic_facebook_circle.svg" />
                <img v-if="user.google_id" src="/static/icon/ic_google_circle.svg" />
              </div>
            </template>
            <template v-else-if="form.field === 'operating_start'">
              <div class="flex-align" style="gap: 8px">
                <div style="flex: 1">
                  <component :is="form.component_name" :ref="form.field" v-bind.sync="form" :useLabel="false" />
                </div>
                <div>~</div>
                <div style="flex: 1">
                  <component
                    :is="formData[index + 1].component_name"
                    :ref="formData[index + 1].field"
                    v-bind.sync="formData[index + 1]"
                    :useLabel="false" />
                </div>
              </div>
            </template>
            <div v-else-if="form.field === 'comm'">
              <div style="padding: 12px 0">{{ commision }}</div>
            </div>
            <component v-else :is="form.component_name" :ref="form.field" v-bind.sync="form" :useLabel="false" />
          </div>
        </div>
        <portal to="footer" :disabled="isMobileWidth">
          <div class="bottom-container">
            <div class="button-wrap">
              <div class="guage-wrap">
                <div>
                  <div class="guage">
                    <div class="bar" :style="`width: ${formValidWidth}%`"></div>
                  </div>
                  <div v-if="guageText.text1" class="body6-medium primary">{{ guageText.text1 }}</div>
                </div>
                <div v-if="guageText.text2" class="body4-medium main">{{ guageText.text2 }}</div>
              </div>
              <div class="buttons">
                <router-link to="/mypage/profile">
                  <button class="button">내 프로필</button>
                </router-link>
                <button class="button is-primary" @click="savePartnerInfo">저장</button>
              </div>
            </div>
          </div>
        </portal>
      </div>
    </div>
  </div>
</template>
<script>
import LpRadio from '../module/LpRadio';
import InputFile from '../module/InputFile';
import InputToggle from '../module/InputToggle';
import LpText from '../component/LpText';
import LpTextArea from '../component/LpTextArea';
import PartnerAPIMixin from '../../mixins/PartnerAPIMixin';
import FormMixin from '../../mixins/FormMixin';
import LpDropDown from '../LpDropDown';
import InputDatePeriod from '../module/InputDatePeriod';
import InputDate from '../module/InputDate';
import InputEditor from '../module/InputEditor';
import InputPhoneVerify from '../module/InputPhoneVerify';
import InputBankInfo from '../module/InputBankInfo';
export default {
  name: 'PartnerInfo',
  mixins: [PartnerAPIMixin, FormMixin],
  components: {
    LpRadio,
    InputFile,
    InputToggle,
    LpText,
    LpTextArea,
    LpDropDown,
    InputDatePeriod,
    InputDate,
    InputEditor,
    InputPhoneVerify,
    InputBankInfo,
  },
  data() {
    return {
      isMobileWidth: true,
      formData: [
        {
          label: '이메일',
          field: 'email',
          component_name: 'LpText',
          disabled: true,
          value: '',
          maxLength: 255,
          validate: {
            required: false,
          },
        },
        {
          label: '연동된 SNS 계정',
          field: 'sns',
          component_name: 'sns',
          validate: {
            required: false,
          },
        },
        {
          label: '파트너 닉네임',
          field: 'nickname',
          type: 'nickname',
          desc: '* 이메일/상호명과 동일한 이름은 사용할 수 없어요.',
          component_name: 'LpText',
          maxLength: 30,
          validate: {
            required: true,
            error_msg: null,
          },
        },
        {
          label: '파트너명',
          field: 'name',
          component_name: 'LpText',
          maxLength: 30,
          validate: {
            valid_required: true,
            required: true,
          },
        },
        {
          label: '대표 전화번호',
          field: 'tel',
          placeholder: '대표 전화번호',
          type: 'phone',
          component_name: 'LpText',
          maxLength: 20,
          validate: {
            valid_required: true,
            required: true,
          },
        },
        {
          label: '대표 휴대전화번호',
          field: 'phone',
          placeholder: '대표 휴대전화번호',
          disabled: false,
          component_name: 'InputPhoneVerify',
          validate: {
            success: false,
            valid_required: true,
            required: true,
          },
        },
        {
          label: '비즈니스 형태',
          field: 'business_type',
          placeholder: '비즈니스 형태',
          component_name: 'LpRadio',
          block: true,
          value: null,
          data: [
            { label: '법인사업자', value: 0 },
            { label: '개인사업자', value: 1 },
            { label: '팀', value: 2 },
            { label: '개인', value: 3 },
          ],
          validate: {
            valid_required: true,
            required: true,
          },
        },
        {
          label: '설립일',
          field: 'est_date',
          value: null,
          defaultNow: false,
          component_name: 'InputDate',
          validate: {
            valid_required: true,
            required: false,
          },
        },
        {
          label: '총 경력기간',
          field: 'career',
          value: '',
          component_name: 'LpDropDown',
          options: [
            { label: '신입', value: '신입' },
            { label: '1년', value: '1년' },
            { label: '2년', value: '2년' },
            { label: '3년', value: '3년' },
            { label: '4년', value: '4년' },
            { label: '5년', value: '5년' },
            { label: '6년', value: '6년' },
            { label: '7년', value: '7년' },
            { label: '8년', value: '8년' },
            { label: '9년', value: '9년' },
            { label: '10년 이상', value: '10년 이상' },
          ],
          validate: {
            required: false,
          },
        },
        {
          label: '연락 가능 시간',
          field: 'operating_start',
          value: null,
          component_name: 'LpDropDown',
          options: [
            { label: '0시', value: '0시' },
            { label: '1시', value: '1시' },
            { label: '2시', value: '2시' },
            { label: '3시', value: '3시' },
            { label: '4시', value: '4시' },
            { label: '5시', value: '5시' },
            { label: '6시', value: '6시' },
            { label: '7시', value: '7시' },
            { label: '8시', value: '8시' },
            { label: '9시', value: '9시' },
            { label: '10시', value: '10시' },
            { label: '11시', value: '11시' },
            { label: '12시', value: '12시' },
            { label: '13시', value: '13시' },
            { label: '14시', value: '14시' },
            { label: '15시', value: '15시' },
            { label: '16시', value: '16시' },
            { label: '17시', value: '17시' },
            { label: '18시', value: '18시' },
            { label: '19시', value: '19시' },
            { label: '20시', value: '20시' },
            { label: '21시', value: '21시' },
            { label: '22시', value: '22시' },
            { label: '23시', value: '23시' },
          ],
          validate: {
            required: false,
          },
        },
        {
          label: '연락 가능 시간',
          field: 'operating_end',
          value: null,
          component_name: 'LpDropDown',
          options: [
            { label: '1시', value: '1시' },
            { label: '2시', value: '2시' },
            { label: '3시', value: '3시' },
            { label: '4시', value: '4시' },
            { label: '5시', value: '5시' },
            { label: '6시', value: '6시' },
            { label: '7시', value: '7시' },
            { label: '8시', value: '8시' },
            { label: '9시', value: '9시' },
            { label: '10시', value: '10시' },
            { label: '11시', value: '11시' },
            { label: '12시', value: '12시' },
            { label: '13시', value: '13시' },
            { label: '14시', value: '14시' },
            { label: '15시', value: '15시' },
            { label: '16시', value: '16시' },
            { label: '17시', value: '17시' },
            { label: '18시', value: '18시' },
            { label: '19시', value: '19시' },
            { label: '20시', value: '20시' },
            { label: '21시', value: '21시' },
            { label: '22시', value: '22시' },
            { label: '23시', value: '23시' },
            { label: '24시', value: '24시' },
          ],
          validate: {
            required: false,
          },
        },
        {
          label: '파트너 소개',
          field: 'info',
          component_name: 'LpTextArea',
          value: '',
          placeholder:
            '전문 분야와 제공하는 서비스, 경험과 강점을 강조하는 설명을 작성해주세요.\n성공적인 프로젝트와 팀 역량을 추가해주시면 더 좋아요.',
          desc: '*이름, 주소, 연락처와 같은 개인 정보를 입력하지 마세요. 해당 내용은 검수 후 삭제될 수 있습니다.\n모든 고객과의 소통은 플랫폼 내 메시지 기능을 통해 이루어집니다.',
          maxLength: 5000,
          validate: {
            required: false,
          },
        },
        {
          label: '사업자등록번호',
          field: 'biz_number',
          type: 'biz',
          value: '',
          component_name: 'lp-text',
          maxLength: 13,
          validate: {
            valid_required: true,
            required: false,
          },
        },
        {
          label: '사업자등록증',
          field: 'img_registration',
          component_name: 'input-file',
          maxLength: 1,
          value: [],
          validate: {
            valid_required: true,
            required: false,
          },
          description: '',
        },
        {
          label: '정산계좌',
          field: 'bank_info',
          value: {
            bank: '',
            account_num: '',
            name: '',
          },
          component_name: 'InputBankInfo',
          validate: {
            valid_required: true,
            required: false,
          },
        },
        {
          label: '수수료',
          field: 'comm',
          validate: {
            required: false,
          },
        },
        {
          label: '알림',
          field: 'manufacture_notification',
          title: '제작 문의 관련 알림',
          component_name: 'InputToggle',
          value: false,
          validate: {
            required: false,
          },
        },
      ],
    };
  },
  created() {
    this.setField('email', this.user.email);
    this.setField('nickname', this.user.partner.nickname);
    this.setField('name', this.user.partner.name);
    this.setField('tel', this.user.partner.tel);
    this.setField('phone', this.user.partner.phone);
    if (this.user.partner.phone) {
      this.formData[5].validate.success = true;
      this.formData[5].disabled = true;
    }
    this.setField('business_type', this.user.partner.business.business_type);
    this.setField('est_date', this.user.partner.business.est_date);
    this.setField('career', this.user.partner.career);
    this.setField('operating_start', this.user.partner.operating_start);
    this.setField('operating_end', this.user.partner.operating_end);
    this.setField('info', this.user.partner.info);
    this.setField('biz_number', this.user.partner.business.biz_number);
    this.setField(
      'img_registration',
      this.user.partner.business.img_registration ? [this.user.partner.business.img_registration] : []
    );
    this.setField('bank_info', this.user.partner.business.bank_info || { bank: '', account_num: '', name: '' });
    this.setField('manufacture_notification', this.user.partner.manufacture_notification);
  },
  mounted() {
    this.isMobileWidth = this.$el.clientWidth <= 600;
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  computed: {
    formValid() {
      const total = this.formData.filter((item) => !['sns', 'comm'].includes(item.field));
      return total.filter((i) => {
        if (Array.isArray(i.value) && i.value.length === 0) {
          return true;
        } else if (i.value === null || i.value === '' || i.value === undefined) {
          return true;
        }
        return false;
      });
    },
    formValidWidth() {
      const total = this.formData.filter((item) => !['sns', 'comm'].includes(item.field));
      const count = total.filter((i) => {
        if (Array.isArray(i.value) && i.value.length === 0) {
          return false;
        } else if (i.value === null || i.value === '' || i.value === undefined) {
          return false;
        }
        return true;
      }).length;
      return (count / total.length) * 100;
    },
    requiredValid() {
      const total = this.formData.filter((item) => item.validate.required);
      const count = total.filter((i) => {
        if (Array.isArray(i.value) && i.value.length === 0) {
          return false;
        } else if (i.value === null || i.value === '' || i.value === undefined) {
          return false;
        }
        return true;
      }).length;
      return total.length === count;
    },
    guageText() {
      let text1 = '';
      let text2 = '';
      if (this.requiredValid) {
        text1 = '필수 정보 입력완료';
        text2 = '파트너 개발자 신청이 가능해요!';
      } else {
        text1 = '필수 정보 미입력';
        text2 = '필수 정보를 입력하고 신뢰도를 높여 더 많은 제작 문의를 받아보세요.';
      }

      if (this.formValidWidth === 100) {
        text1 = '모든 정보 입력완료';
        text2 = '템플릿 판매준비가 완료되었어요!';
      }
      return {
        text1,
        text2,
      };
    },
    commision() {
      const comm = this.user.partner.business.commision;
      if (comm && comm.enable) {
        if (comm.type === 0) {
          return `${comm.rate}%`;
        } else {
          return `${comm.price}원`;
        }
      }
      return '-';
    },
  },
  methods: {
    handleResize() {
      this.isMobileWidth = this.$el.clientWidth <= 600;
    },
    setField(field, value) {
      let form = this.formData.find((item) => item.field === field);
      if (form) {
        form.value = value;
      }
    },
    savePartnerInfo() {
      let param = this.cloneItem(this.getParamData(this.formData));
      if (!param.enable) {
        return;
      }
      if (param.params.nickname === param.params.name || param.params.nickname === param.params.email) {
        this.toast('이메일/상호명과 동일한 이름은 사용할 수 없어요.');
        return;
      }
      param.params.img_registration = param.params.img_registration[0];

      this.setLoading('저장중입니다.');
      this.request.partner
        .patch('profile', param.params)
        .then((res) => {
          this.clearLoading();
          this.user.partner = res.data.user.partner;
          this.$store.commit('setUser', this.user);
          this.toast('저장되었습니다.');
          this.$router.back();
        })
        .catch(() => {
          this.clearLoading();
          this.toast('저장에 실패했습니다.');
        });
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/css/lp_main'
.partner-info-container
  padding 64px 0 0 0
.partner-info
  padding 40px 0 0 0
  .title
    font-size 24px
    font-weight 700
    color $main
  .content
    margin-top 24px
    background-color white
    padding 40px
    border-radius 12px
    .form-item
      display flex
      gap 8px
      padding 24px 0
      border-top 1px solid $gray1
      &:first-child
        border-top none
      .form-label
        font-size 15px
        width 172px
        padding 12px 0
        color $sub
      .form-content
        flex 1

.required
  color $error
.sns
  padding 6px 0
  img
    width 32px
    height 32px

.bottom-container
  margin-top 40px
  background-color white
  width 100%
  border-top 1px solid $gray
  display flex
  justify-content center

.button-wrap
  width 1152px
  background-color white
  padding 24px 0
  display flex
  justify-content space-between
  align-items center
  gap 12px
  .guage-wrap
    display flex
    gap 16px
  .buttons
    display flex
    gap 8px
  .button
    width 160px
    font-size 16px
    font-weight 700
    height 52px !important

  .guage
    margin-top 8px
    width 268px
    height 8px
    border-radius 20px
    background-color $gray
    overflow hidden
    .bar
      background-color $primary
      height 100%
@media (max-width: 600px)
  .partner-info
    padding 24px 16px 16px 16px
    .content
      padding 32px 16px
      margin-top 12px
  .button-wrap
    display block
    .guage-wrap
      flex-direction column
      .guage
        width 100%
    .buttons
      margin-top 40px
      a
      .button
        width 100%
        flex 1

  .form-item
    flex-direction column
</style>
